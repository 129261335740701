.section3{
    margin:4rem auto;
    width:90%;
}

.section3 .heading {
    text-align: center;
    font-weight: 700;
    font-size: 34px;
    margin: 1rem 0;
}


.gif{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.gif iframe{
    width: 40%;
}
.gif iframe:nth-child(1){
    height: 300px;
}.gif iframe:nth-child(3){
    width: 40%;
    height: 300px;
}
.gif iframe:nth-child(5){
    width: 90%;
    height: 500px;
}
.gif iframe::-webkit-scrollbar{
    display: none;
}

.bottom{
    text-align: center;
}

.bottom button{
    margin: auto;
    background: var(--bg);
    font-size: 1.4rem;
    font-weight: 500;
    border: 0;
    cursor: pointer;
    padding: 0.9em 1.3em;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #ffff;
    white-space: nowrap;
    border-radius: 100px;
    position: relative;
    box-shadow:
        0 0 calc(var(--active) * 6em) calc(var(--active) * 3em) hsl(260 97% 61% / 0.75),
        0 0.05em 0 0 hsl(260 calc(var(--active) * 97%) calc((var(--active) * 50%) + 30%)) inset,
        0 -0.05em 0 0 hsl(260 calc(var(--active) * 97%) calc(var(--active) * 60%)) inset;
    transition: box-shadow var(--transition), scale var(--transition), background var(--transition);
    scale: calc(1 + (var(--active) * 0.1));
    transition: .3s all ease-in-out;
}

.bottom button:hover{
    background: linear-gradient(85.06deg, #FF02F0 0.01%, #8930FD 95.64%);
}

.bottom .text{
    font-weight: 500;
    font-size:18px;
}

@media screen and (max-width:770px) {
    .bottom .two-arrows{
        width:40%;
    }

    .bottom button{
        padding:1.5rem 2rem;
    }
    .gif iframe{
        width: 60%;
    }
    .gif iframe:nth-child(3){
        width: 60%;
        height: 300px;
    }
    .gif iframe:nth-child(5){
        width: 80%;
        height: 500px;
    }
    
}
@media(max-width:500px){
    .gif{
        gap: 10px;
    }
    .gif iframe{
        width: 100%;
        margin: auto;
    }
    .gif iframe:nth-child(1){
        height: 200px;
    }
    .gif iframe:nth-child(3){
        width: 100%;
        height: 200px;
    }
    .gif iframe:nth-child(5){
        width: 100%;
        height: 460px;
    }
    
}
@media screen and (max-width:390px) {
    .bottom .two-arrows{
        width:40%;
    }

    .bottom button{
        padding:1rem 1.5rem;
    }

    .bottom .text{
        font-size:16px;
    }
    
}
.bottom a{
    color: var(--primary-font-color);
    text-decoration: none;
}


