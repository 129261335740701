nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 60px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
    background-color: rgb(0, 0, 0, 0.3);
    backdrop-filter: blur(50px);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15);
}

.time {
    color: #DBFF00;
    text-align: center;
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 200;
    line-height: 14px;
    letter-spacing: -0.84px;
}

.nav-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10rem;
}

.nav-logo img {
    width: 10rem;
}

.nav-head {
    font-size: 22px;
    font-weight: 700;
}

.nav-links {
    font-weight: 200;
    font-size: 17px;
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
}

.nav-links a,
.hamburger-menu a {
    color: var(--primary-font-color);
    text-decoration: none;
}

.nav-links span {
    cursor: pointer;
}

.hamburger {
    display: none !important;
    box-sizing: content-box;
    width: 25px;
    padding: 0.45rem 0.35rem;
    border-radius: 5px;
    flex-direction: column;
    gap: 0.25rem;
    overflow: hidden;
}

.hamburger svg {
    font-size: 40px;
}

.hamburger-menu {
    height: 100vh;
    background-color: var(--primary-backround-color);
    position: absolute;
    box-shadow: 9px 0px 20px #fff;
    top: 0;
    right: 0;
    display: flex;
    color: white;
    flex-direction: column;
    gap: 10px;
    z-index: 9999;
    width: 65%;
}

.hamburger-menu span{
    margin-top: 8rem;
}
.hamburger-menu span , 
.hamburger-menu a{
    padding: 10px;
    color: var(--primary-font-color);
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;

}
.hamburger-cross {
    position: absolute;
    color: var(--primary-font-color);
    padding: 0.3rem 0.5rem;
    border-radius: 50%;;
    font-size: 45px;
    font-weight: 600;
    top: 3%;
    right: 0;
    cursor: pointer;
}

@media screen and (max-width:700px) {

    nav {
        padding: 1.5rem 2rem;
    }

    .hamburger {
        display: flex !important;
    }

    .nav-links {
        display: none;
    }
    .nav-logo {
        margin-left: 0;
    }
}

@media screen and (max-width:430px) {

    nav {
        padding: 1.2rem;
    }
}