.section2{
    margin-bottom:3rem;
}

.section2 .sub-head{
    text-align: center;
    width:90%;
    margin: 1rem auto 2rem ;
    font-weight: 700;
    font-size:34px;
}

.section2 .container{
    overflow: hidden;
}

.section2 .container .container-hidden {
    display: flex;
    gap: 2.5rem;
    padding: 0.6rem 3rem;
    animation: scroll-left-right 6s linear infinite;
}

.creator-box:hover{
    transform: scale(1.125)
}

.section2 .container span{
    background-color: var(--primary-bg-color);
    color: var(--secondary-font-color);
    padding: 0.5rem 1rem;
    width: max-content;
    font-size: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    top:-12%;
    left:50%;
    transform: translateX(-50%);
    font-size: 14px;
    font-weight: 600;
    margin-top: .2rem;
}

@media screen and (max-width:1750px) {
    
    @keyframes scroll-left-right {
        0%,20%{
            transform: translateX(0);
        }
        20%,40%{
            transform: translateX(-10%);
        }
        40%,60%{
        transform: translateX(-20%);
        }
        60%,80%{
            transform: translateX(-10%)
        }
        80%,100%{
            transform: translateX(0%);
        }
    }

}

@media screen and (max-width:1444px) {
    @keyframes scroll-left-right {
        0%,20%{
            transform: translateX(0);
        }
        20%,40%{
            transform: translateX(-20%);
        }
        40%,60%{
        transform: translateX(-35%);
        }
        60%,80%{
            transform: translateX(-20%)
        }
        80%,100%{
            transform: translateX(0%);
        }
    }
}


@media screen and (max-width:1300px) {
    @keyframes scroll-left-right {
        0%,20%{
            transform: translateX(0);
        }
        20%,40%{
            transform: translateX(-30%);
        }
        40%,60%{
        transform: translateX(-45%);
        }
        60%,80%{
            transform: translateX(-30%)
        }
        80%,100%{
            transform: translateX(0%);
        }
    }
}


@media screen and (max-width:1200px) {
    @keyframes scroll-left-right {
        0%,20%{
            transform: translateX(0);
        }
        20%,40%{
            transform: translateX(-40%);
        }
        40%,60%{
        transform: translateX(-55%);
        }
        60%,80%{
            transform: translateX(-40%)
        }
        80%,100%{
            transform: translateX(0%);
        }
    }
}

@media screen and (max-width:1110px) {
    @keyframes scroll-left-right {
        0%,20%{
            transform: translateX(0);
        }
        20%,40%{
            transform: translateX(-60%);
        }
        40%,60%{
        transform: translateX(-75%);
        }
        60%,80%{
            transform: translateX(-60%)
        }
        80%,100%{
            transform: translateX(0%);
        }
    }
}

@media screen and (max-width:1000px) {

    .creator-box img {
        width:120px;
    }

    @keyframes scroll-left-right {
        0%,20%{
            transform: translateX(0);
        }
        20%,40%{
            transform: translateX(-50%);
        }
        40%,60%{
        transform: translateX(-65%);
        }
        60%,80%{
            transform: translateX(-50%)
        }
        80%,100%{
            transform: translateX(0%);
        }
    }
}

@media screen and (max-width:900px) {

    .creator-box img {
        width:110px;
    }

    @keyframes scroll-left-right {
        0%,20%{
            transform: translateX(0);
        }
        20%,40%{
            transform: translateX(-60%);
        }
        40%,60%{
        transform: translateX(-76%);
        }
        60%,80%{
            transform: translateX(-60%)
        }
        80%,100%{
            transform: translateX(0%);
        }
    }
}

@media screen and (max-width:800px) {

    .creator-box img {
        width:100px;
    }

    @keyframes scroll-left-right {
        0%,20%{
            transform: translateX(0);
        }
        20%,40%{
            transform: translateX(-70%);
        }
        40%,60%{
        transform: translateX(-89%);
        }
        60%,80%{
            transform: translateX(-70%)
        }
        80%,100%{
            transform: translateX(0%);
        }
    }
}

@media screen and (max-width:710px) {

    @keyframes scroll-left-right {
        0%,20%{
            transform: translateX(0);
        }
        20%,40%{
            transform: translateX(-80%);
        }
        40%,60%{
        transform: translateX(-110%);
        }
        60%,80%{
            transform: translateX(-80%)
        }
        80%,100%{
            transform: translateX(0%);
        }
    }
}

@media screen and (max-width:650px) {
    
    .section2 .container .container-hidden {
        animation-duration: 10s;
    }
    

    .section2 .sub-head{
        width:90%;
    }

    @keyframes scroll-left-right {
        0%,20%{
            transform: translateX(0);
        }
        20%,40%{
            transform: translateX(-100%);
        }
        40%,60%{
        transform: translateX(-160%);
        }
        60%,80%{
            transform: translateX(-100%)
        }
        80%,100%{
            transform: translateX(0%);
        }
    }
}

@media screen and (max-width:520px) {

    @keyframes scroll-left-right {
        0%,20%{
            transform: translateX(0);
        }
        20%,40%{
            transform: translateX(calc( 90vw - 1100px )  );
        }
        40%,60%{
        transform: translateX( calc( 90vw - 1305px) );
        }
        60%,80%{
            transform: translateX( calc( 90vw - 1100px ) )
        }
        80%,100%{
            transform: translateX(0%);
        }
    }
}