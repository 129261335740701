footer{
    padding: 1vw 6vw;
    display: flex;
    flex-wrap: wrap;
    background: radial-gradient(circle, rgba(255,0,138,1) 27%, rgba(188,23,202,1) 46%, rgba(0,41,255,1) 78%);
    color: #DBFF00;
    align-items: center;
    justify-content: space-between;
}
footer .head{
    font-weight: 100;
    font-size: 30px;
}
.footer-links{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.3rem;
    justify-content: center;
}

.footer-links a{
    color:  #DBFF00;
    text-decoration: none;
    font-size: 26px;
    margin-top: 1.7px;
}
.designed-text{
    text-align: center;
    font-size:19px;
    font-weight: 150;
    margin-bottom: 7px;
}

@media screen and (max-width:900px) {

    .footer-links{
        margin-right: 0;
    }
}

@media screen and (max-width:660px) {
    footer{
        padding: 10px;
    }
    .footer-links{
        gap:3.3vw
    }

    footer .head{
        font-size: 5vw;
    }
    .footer-links{
        margin-left: 20px;
    }
   .footer-links a{
    font-size: 5vw;
   }

   .designed-text{
    font-size: 3vw;
   }
}