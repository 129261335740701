.section-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    height: 90vh;
    text-align: center;
}

.section-1 .heading {
    font-size: 3.85rem;
    ;
    font-weight: 700;
    word-spacing: 0.75rem;
    letter-spacing: 0;
}

.section-1 .heading div:first-of-type {
    margin-bottom: 0.75rem;
}

.section-1 .small-text {
    line-height: 1.75rem;
    font-weight: 400;
    font-size: 17px;
    width: 31%;
    margin: auto;
    margin: 0;
}

.section-1 .btn-container {
    margin: 0;
}


.section-1 .btn-container button {
    background: var(--bg);
    font-size: 1.4rem;
    font-weight: 500;
    border: 0;
    cursor: pointer;
    padding: 0.9em 1.3em;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #ffff;
    white-space: nowrap;
    border-radius: 100px;
    position: relative;
    box-shadow:
        0 0 calc(var(--active) * 6em) calc(var(--active) * 3em) hsl(260 97% 61% / 0.75),
        0 0.05em 0 0 hsl(260 calc(var(--active) * 97%) calc((var(--active) * 50%) + 30%)) inset,
        0 -0.05em 0 0 hsl(260 calc(var(--active) * 97%) calc(var(--active) * 60%)) inset;
    transition: box-shadow var(--transition), scale var(--transition), background var(--transition);
    scale: calc(1 + (var(--active) * 0.1));
    transition: .3s all ease-in-out;
}
.section-1 .btn-container button:hover{
    transform: scale(1.1);
}




.section-1 .header-line-2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-scroll-container {
    overflow: hidden;
    height: 5rem;
    padding: 0 1rem;
    width: max-content;
    display: inline-block;
    margin-right: 0.5rem;
}

.vertical-scroll-hidden {
    animation: scroll-up-down 5.5s linear infinite;
    display: flex;
    flex-direction: column;
}

.vertical-scroll-container .word {
    background: linear-gradient(270deg, #FFC800 -7.21%, #FF02F0 62.98%, #8930FD 99.92%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 500;
    width: fit-content;
    margin: auto;
}

@keyframes scroll-up-down {

    0%,
    15% {
        transform: translateY(0);
    }

    20%,
    30% {
        transform: translateY(-20%);
    }

    40%,
    50% {
        transform: translateY(-40%);
    }

    60%,
    70% {
        transform: translateY(-60%);
    }

    80%,
    90% {
        transform: translateY(-80%);
    }
}


@media screen and (max-width:1024px) {

    .section-1 .small-text {
        width: 50%;
    }

    .section-1 .heading {
        font-size: 3.5rem;
    }

}

@media screen and (max-width:800px) {

    .section-1 .small-text {
        width: 60%;
    }

    .section-1 .heading {
        font-size: 3rem;
    }

    .vertical-scroll-container {
        height: 4rem;
    }

}

@media screen and (max-width:705px) {

    .section-1 .small-text {
        width: 70%;
    }

    .section-1 .heading {
        font-size: 2.67rem;
    }

    .vertical-scroll-container {
        height: 3.3rem;
    }

}

@media screen and (max-width:660px) {

    .section-1 .small-text {
        width: 73%;
    }

    .section-1 .heading {
        font-size: 2rem;
    }

    .vertical-scroll-container {
        height: 2.5rem;
    }

}

@media screen and (max-width:450px) {

    .section-1 {
        height: 70vh;
        padding-top: 5rem;
    }

    .section-1 .header-line-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .section-1 .heading {
        word-spacing: 0.2rem;
    }

    .section-1 .small-text {
        width: 85%;
    }

    .section-1 .heading {
        font-size: 1.5rem;
    }

    .vertical-scroll-container {
        height: 2rem;
    }

    .section-1 .btn-container button {
        padding: 1.4rem 1.36em;
        font-size: 1.2rem;
    }

    .section-1 .small-text {
        font-size: 16px;
    }

}

@media(max-width:376px){
    .section-1 {
        height: 96vh;
        padding-top: 5rem;
    }
}