.section5{
    margin : 6rem auto;
}

.section5 .heading{
    width:90%;
    margin: auto;
    font-weight: 700;
    font-size: 34px;
    text-align: center;
}



.article-container-hidden{
    width: 2000px;
    margin: 2rem 0;
    display: flex;
    gap:1rem;
    animation: users-scroll 7s infinite;
}

.article-container-hidden:hover{
    animation-play-state: paused;
}

.article-container{
    padding-left:5%;
  overflow-x: hidden;
}

article{
    max-width: 500px;
    width:500px;
    margin:0;
    padding:3rem 1.5rem; 
    border: 1px solid #D9D9D9;
    background-color: var(--secondary-bg-color);
    box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
    border-radius:12px;
}

article:hover{
    transform: scale(1.12);
}

.article-head{
    color: var(--primary-bg-color);
    font-size:18px;
    margin-bottom:0.75rem;
}

.article-text{
    margin-bottom:2rem;
}

.article-content{
    color: black;
    font-size:16px;
}

.article-user{
    color: #170F49;
    display: flex;
    align-items: center;
}

.user-detail{
    margin-left:0.75rem;
    display: flex;
    flex-direction: column;
}

.username{
    margin-bottom:0.35rem;
    font-weight: 400;
    font-size:18px;
}

.user-work{
    font-size: 16px;
    color: var(--article-text-color)
}

@media screen and (max-width:2250px) {

    @keyframes users-scroll {
        0%,15%{
            transform: translateX(0);
        }
        15%,30%{
            transform: translateX( calc( 100vw - 1500px ) );
        }
        30%,45%{
            transform: translateX( calc( 95vw - 2000px ) );
        }
        45%,60%{
            transform: translateX( calc( 85vw - 2000px ) );
        }
        60%,75%{
            transform: translateX( calc( 95vw - 2000px ) );
        }
        75%,85%{
            transform: translateX( calc( 100vw - 1500px ) );
        }
        85%,100%{
            transform:  translateX(0);;
        }
    }

}

@media screen and (max-width:768px) {

    .article-container-hidden{
        animation-duration: 10s;
    }
}

@media screen and (max-width:650px) {

    .section5 .heading{
        font-size: 32px;
    }

    article{
        width:90vw;
        padding:2rem 1rem;
    }

    .article-head{
        font-size: 17px;
    }

    .article-content{
        font-size: 15px;
    }

    .article-container-hidden{
        width: 400vw;
    }

    @keyframes users-scroll {
        0%,15%{
            transform: translateX(0);
        }
        15%,30%{
            transform: translateX( -92vw);
        }
        30%,45%{
            transform: translateX( -184vw );
        }
        45%,60%{
            transform: translateX( -280vw );
        }
        60%,75%{
            transform: translateX( -184vw );
        }
        75%,85%{
            transform: translateX( -92vw );
        }
        85%,100%{
            transform:  translateX(0);;
        }
    }

}
@media(max-width:400px){
    
.section5 .heading{
    font-size: 28px;
}
}