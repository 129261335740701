@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

/* font-family: 'Inter', sans-serif; */

@import url('./components/Navigation/navigation.css');
@import url('./components/Section1/section1.css');
@import url('./components/Section2/section2.css');
@import url('./components/Section3/section3.css');
@import url('./components/Section4/section4.css');
@import url('./components/Section5/section5.css');
@import url('./components/Section6/section6.css');
@import url('./components/Footer/footer.css');
@import url('./components/Section7/section7.css');

:root{
  --primary-font-color: #DBFF00;
  --primary-bg-color: #ff008a;
  --secondary-font-color: white;
  --secondary-bg-color:#F8F8F8;
  --article-text-color: #6F6C90;
  --tertiary-bg-color: #F8F8F8;
  --dark-bg-color:#E6E6E6;
  --primary-backround-color: #101010;
  --cut: 0.1em;
  --active: 0;
  --bg:
  radial-gradient(40% 50% at center 100%,
      hsl(270 calc(var(--active) * 97%) 72% / var(--active)),
      transparent),
  radial-gradient(80% 100% at center 120%,
      hsl(260 calc(var(--active) * 97%) 70% / var(--active)),
      transparent),
  hsl(260 calc(var(--active) * 97%) calc((var(--active) * 44%) + 12%));
  --transition: 0.25s;
	--spark: 1.8s;
}

*{
  margin:0;
  padding:0;
  text-decoration: none;
  list-style-type: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body{
  background-color: var(--primary-backround-color);
  color: var(--primary-font-color);
  font-family: 'Inter', sans-serif;
}

button{
  cursor: pointer;
}

::-webkit-scrollbar{
  display: none;
}