.section4{
    width:90%;
    margin:6rem auto;
}
.section4 .header{
    text-align: center;
}
.section4 .header .head {
    font-weight:700;
    font-size:36px;
}
.section4 .header .smallText{
    color: #DBFF00;
    text-align: inherit;
    font-size: 13px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.9px;
}

.cards-container{
    margin:3.5rem 0 5rem  ;
    display:flex;
    justify-content: space-evenly;
}

.cards-container .card{
    background-color: var(--primary-bg-color);
    padding: 2rem 1.5rem;
    width:22%;
    border-radius:10px;
}

.cards-container .card .text-content{
    margin-top:1.5rem;
    color:var(--secondary-font-color);
}

.cards-container .card .text-content .head{
    font-weight:600;
    font-size:18px;
}

.cards-container .card .text-content .text{
    font-size:14px;
}

.section4 .explore-btn{
    display: block;
    padding:2rem 3.5rem ;
    color: var(--secondary-font-color);
    border-radius: 4rem;
    font-size:20px;
    bordeR:none;
    background: linear-gradient(85.08deg, #FFC800 7.5%, #FF02F0 95.63%);;
    margin:4rem auto;
}

.section4 .explore-btn:hover{
    background: linear-gradient(85.06deg, #FF02F0 0.01%, #8930FD 95.64%);
}

@media screen and (max-width:1000px) {
    .cards-container{
        flex-wrap: wrap;
        gap:1rem;
    }

    .cards-container .card{
        width:45%;
    }

    .section4 .explore-btn{
        padding: 2rem 2.5rem;
    }

}

@media screen and (max-width:550px) {
    .cards-container .card{
        width:85%;
        margin:auto;
    }

    .section4 .explore-btn{
        padding: 1.5rem 2rem;
    }

    .section4 .header .head {
        font-size:32px;
    }
    
}

@media screen and (max-width:370px) {
  .cards-container .card{
        width:90%;
        margin:auto;
    }

    .section4 .explore-btn{
        padding: 1.5rem 2rem;
    }

    .section4 .header{
        text-align: center;
    }
    .section4 .header .head {
        font-weight:700;
        font-size: 24px;
    }
    
}