.section6{
    width:78%;
    margin: 6rem auto;
    background-color: var(--dark-bg-color);
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding:0 2rem 1rem 0rem ;
}
.lightText{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6rem;
    padding: 0 3rem;
}
.leftParaText{
    color: #DBFF00;
text-align: center;
font-size: 24px;
font-family: Inter;
font-style: normal;
font-weight: 100;
line-height: 30px;
letter-spacing: -1.44px;
}

.section6 .container{
    padding:0 0 0 3rem;
    display: flex;
    align-items: center;
}

.section6 .head{
    flex-basis:65%;
    display: inline;
    font-weight:700;
    font-size:44px;
}

.section6 button{
    height: max-content;
    margin-left: auto;
    padding:1.5rem;
    border:none;
    font-size:20px;
    border-radius: 4rem;
    background-color: var(--primary-bg-color);
    color:var(--secondary-font-color);
    width:max-content;
}


.section6 .logo{
    align-self: flex-start;
}

@media screen and (max-width:1024px) {

    .section6 {
        align-items: flex-end;
    }

    .section6 .head{
        flex-basis: unset;
        font-size:4vw;
        width:80%;
    }

    .section6 .logo{
    width:20vw
    }

    .section6 .container{
        padding:0 1rem 0;
        flex-wrap: wrap;
    }

    .section6 button{
        margin-left:auto;
        font-size:2.5vw;
        margin-top:0.5rem;
        padding:1rem;
    }

    .section6 button img{
        width:2.3vw;
    }

}

@media screen and (max-width:770px) {
  
    .section6{
        padding-right: 1rem;
        padding-bottom: 1rem;
    }

    .section6 .container{
        padding:0 0 0 1rem;
    }

    .section6 button{
        padding:2vw;
    }

    .section6 button img{
        width:2vw;
    }

}
@media(max-width:500px){
    .lightText{
        flex-direction: column;
        gap: 30px;
        margin-bottom: 2rem;
        padding: 0 0;
    }
    .leftParaText{
        width: 100%;
    }
}